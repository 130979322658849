/* eslint-disable no-useless-escape,no-underscore-dangle */
import LogoutIcon from "@mui/icons-material/Logout";
import CreateIcon from "@mui/icons-material/Create";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import GroupIcon from "@mui/icons-material/Group";
import TopicIcon from "@mui/icons-material/Topic";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssignmentIcon from '@mui/icons-material/Assignment';
import WorkIcon from '@mui/icons-material/Work';
import HttpsIcon from '@mui/icons-material/Https';

export const LocalStorageName = "posrednik24";

export const IMAGE_FILE_EXTENSIONS = ["jpg", "jpeg", "png"];

export const PHOTO_INFO_DOC_URL = 'https://posrednik24-public.s3.eu-central-1.amazonaws.com/Photo+Instructions.pdf';
export const UrlRegex =
  /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

export const DrawerWidth = 240;
export const MobileWidth = 900;

export const UserVerificationSteps = {
  PERSONAL_INFORMATION: {
    label: "Personal Information",
    value: 0,
  },
  EDUCATION: {
    label: "Education",
    value: 2,
  },
  WORK_EXPERIENCE: {
    label: "Work Experience",
    value: 3,
  },
  SKILLS: {
    label: "Skills",
    value: 4,
  },
  BANK_DETAILS: {
    label: "Bank Details",
    value: 5,
  },
  JOB_EQUIPMENT: {
    label: "Job Equipment",
    value: 6,
  },
};

export const CompanyVerificationSteps = {
  COMPANY_DETAILS: {
    label: "Company Details",
    value: 0,
  },
  CONTACT_PERSON: {
    label: "Contact Person",
    value: 2,
  },
};

export const PromoterVerificationSteps = {
  PERSONAL_DETAILS: {
    label: "Personal Details",
    value: 0,
  },
  REFERRAL_CODE: {
    label: "Referral Code",
    value: 2,
  },
}

export const MainMenu = [
  {
    label: "Jobs",
    to: "/jobs",
  },
  {
    label: 'Services',
    children: [
      {
        label: 'Student Jobs Germany',
        to: 'https://posrednik24.com/en/student-jobs-de/'
      },
      {
        label: 'Visa Jobs',
        to: 'https://posrednik24.com/en/visa-jobs/'
      },
      {
        label: 'EU Jobs',
        to: 'https://posrednik24.com/en/eu-jobs/'
      },
      {
        label: 'Croatia Jobs',
        to: 'https://posrednik24.com/en/croatia-jobs/'
      },
      {
        label: 'Tax Refund',
        to: 'https://posrednik24.com/tax-refund/'
      },
      {
        label: 'Insurance',
        to: 'https://posrednik24.com/insurance/'
      },
      {
        label: 'Transport',
        to: 'https://posrednik24.com/transport/'
      },
      {
        label: 'Language Courses',
        to: 'https://posrednik24.com/language-courses/'
      },
    ]
  },
  {
    label: 'Experiences',
    children: [
      {
        label: 'Gallery',
        to: 'https://posrednik24.com/gallery/'
      },
      {
        label: 'Stories',
        to: 'https://posrednik24.com/stories/'
      },
    ],
  },
  {
    label: 'FAQ',
    to: 'https://posrednik24.com/faq/',
  },
  {
    label: "About Us",
    to: "https://posrednik24.com/about-us/",
  },
  {
    label: "Contact",
    to: "https://posrednik24.com/contact/",
  },
];

export const EnumFunctions = {
  _getProperties() {
    const properties = Object.getOwnPropertyNames(this).filter((i) => typeof this[i] !== "function");
    return properties.reduce((acc, i) => {
      acc[i] = this[i].label;
      return acc;
    }, {});
  },
  asList() {
    const obj = this._getProperties();
    return Object.keys(obj).map((i) => ({ label: obj[i], value: i }));
  },
  getLabels() {
    return Object.values(this._getProperties()).map((i) => i);
  },
  getValues() {
    return Object.keys(this._getProperties()).map((i) => i);
  },
  getLabel(val) {
    return this._getProperties()[val];
  },
  getItemByValue(val) {
    const properties = Object.getOwnPropertyNames(this).filter((i) => typeof this[i] !== "function");
    return this[properties.filter((i) => i === val)];
  }
};

export const Roles = {
  ADMIN: {
    value: "ADMIN",
    label: "Admin",
  },
  USER: {
    value: "USER",
    label: "User",
  },
  COMPANY: {
    value: "COMPANY",
    label: "Company",
  },
  PROMOTER: {
    value: "PROMOTER",
    label: "Promoter",
  },
  ...EnumFunctions,
};

export const DrawerItems = ({ isFirstLogin, role }) => ([
  {
    title: role === Roles.PROMOTER.value ? "Users" : "Dashboard",
    icon: DashboardIcon,
    route: "/dashboard",
  },
  {
    title: "Profile",
    icon: AccountBoxIcon,
    route: "/profile",
    roles: [Roles.USER.value, Roles.COMPANY.value, Roles.PROMOTER.value],
  },
  {
    title: "Jobs",
    icon: WorkIcon,
    route: "/jobs",
    roles: [Roles.ADMIN.value, Roles.COMPANY.value, Roles.PROMOTER.value],
  },
  {
    title: "Create Job",
    icon: CreateIcon,
    route: "/jobs/form",
    roles: [Roles.ADMIN.value, Roles.COMPANY.value],
  },
  {
    title: "Users",
    icon: GroupIcon,
    route: "/users",
    roles: [Roles.ADMIN.value],
  },
  {
    title: "Documents",
    icon: TopicIcon,
    route: "/documents",
    roles: [Roles.ADMIN.value, Roles.USER.value],
  },
  {
    title: "Applications",
    icon: AssignmentIcon,
    route: "/applications",
    roles: [Roles.USER.value],
  },
  {
    title: "Notifications",
    icon: NotificationsIcon,
    route: "/notifications",
  },
  {
    title: isFirstLogin ? "Set Password" : "Change Password",
    icon: HttpsIcon,
    route: "/change-password",
    roles: [Roles.USER.value, Roles.COMPANY.value]
  },
  {
    title: "Sign Out",
    icon: LogoutIcon,
  },
]);

export const JobCategories = {
  SEASONAL_JOB: {
    value: "SEASONAL_JOB",
    label: "Seasonal Job",
    color: '#C02026',
    textColor: 'white'
  },
  STUDENT_JOB: {
    value: "STUDENT_JOB",
    label: "Student Job",
    color: 'yellow',
    textColor: 'black'
  },
  VISA_JOB: {
    value: "VISA_JOB",
    label: "Visa Job",
    color: 'green',
    textColor: 'white'
  },
  EU_PASSPORT_JOB: {
    value: "EU_PASSPORT_JOB",
    label: "EU Passport Job",
    color: 'blue',
    textColor: 'white'
  },
  ...EnumFunctions,
};

export const NotificationTypes = {
  JOB_CREATED: {
    value: 'JOB_CREATED',
    label: 'Job Created',
    roles: [Roles.ADMIN.value]
  },
  JOB_UPDATED: {
    value: 'JOB_UPDATED',
    label: 'Job Updated',
    roles: [Roles.ADMIN.value]
  },
  JOB_STATUS_UPDATED: {
    value: 'JOB_STATUS_UPDATED',
    label: 'Job Status Updated',
    roles: [Roles.ADMIN.value]
  },
  USER_APPLIED: {
    value: 'USER_APPLIED',
    label: 'Job Application',
    roles: [Roles.ADMIN.value]
  },
  APPLICATION_STATUS_UPDATED: {
    value: 'APPLICATION_STATUS_UPDATED',
    label: 'Application Status Updated',
  },
  USER_REGISTERED: {
    value: 'USER_REGISTERED',
    label: 'User Registration',
    roles: [Roles.ADMIN.value]
  },
  USER_VERIFIED_EMAIL: {
    value: 'USER_VERIFIED_EMAIL',
    label: 'User Email Verification',
    roles: [Roles.ADMIN.value]
  },
  USER_VERIFIED_PROFILE: {
    value: 'USER_VERIFIED_PROFILE',
    label: 'User Profile Verification',
    roles: [Roles.ADMIN.value]
  },
  COMPANY_VERIFIED_PROFILE: {
    value: 'COMPANY_VERIFIED_PROFILE',
    label: 'Company Profile Verification',
    roles: [Roles.ADMIN.value]
  },
  ...EnumFunctions,
}

export const JobTimeTypes = {
  FULL_TIME: {
    value: "FULL_TIME",
    label: "Full Time",
  },
  PART_TIME: {
    value: "PART_TIME",
    label: "Part Time",
  },
  TEMPORARY: {
    value: "TEMPORARY",
    label: "Temporary",
  },
  INTERN: {
    value: "INTERN",
    label: "Intern",
  },
  ...EnumFunctions,
};

export const ApplicationStatus = {
  ACCEPTED: {
    value: "ACCEPTED",
    label: "Accepted",
  },
  REJECTED: {
    value: "REJECTED",
    label: "Rejected",
  },
  PENDING: {
    value: "PENDING",
    label: "Pending",
  },
  ...EnumFunctions,
};

export const SalaryType = {
  HOURLY: {
    value: "HOURLY",
    label: "Hourly",
  },
  MONTHLY: {
    value: "MONTHLY",
    label: "Monthly",
  },
  ...EnumFunctions,
};

export const RequiredDocuments = {
  PASSPORT: {
    value: "PASSPORT",
    label: "Passport",
  },
  ...EnumFunctions,
};

export const JobStatus = {
  ACTIVE: {
    value: "ACTIVE",
    label: "Active",
  },
  PAUSED: {
    value: "PAUSED",
    label: "Paused",
  },
  CLOSED: {
    value: "CLOSED",
    label: "Closed",
  },
};

export const hobbies = [
  "Photography",
  "Football",
  "Basketball",
  "Tennis",
  "Table Tennis",
  "Badminton",
  "Yoga",
  "Art",
  "Skiing",
  "Cycling",
  "Pottery",
  "Movies",
  "Reading",
  "Dance",
  "Running",
  "Swimming",
  "Mountain climbing",
  "Volleyball",
  "Board games",
  "Writing",
  "Design",
  "Painting",
  "Languages",
  "Camping",
];

export const Skills = [
  "Communication",
  "Creativity",
  "Problem-solving",
  "Teamwork",
  "Leadership",
  "Organization",
  "Work ethic",
  "Attention to detail",
  "Public-speaking",
  "Microsoft Office",
  "Social Media",
  "SEO",
  "People management",
  "Project management",
  "Time management",
  "Logistics",
  "Research skills",
  "Analytical Skills",
  "Critical thinking",
  "Decision-making skills",
  "Planning",
  "Scheduling",
  "Motivation",
  "Strategic thinking",
  "Persuasion skills",
  "Positivity",
  "Adaptability",
];

export const LanguageLevels = ["Native", "A1", "A2", "B1", "B2", "C1", "C2"];

export const languages = [
  { code: 'af', name: 'Afrikaans' },
  { code: 'ar', name: 'Arabic' },
  { code: 'hy', name: 'Armenian' },
  { code: 'az', name: 'Azerbaijani' },
  { code: "sq", name: "Albanian" },
  { code: 'be', name: 'Belarusian' },
  { code: 'bn', name: 'Bengali (Bangla)' },
  { code: 'bs', name: 'Bosnian' },
  { code: 'bg', name: 'Bulgarian' },
  { code: 'ca', name: 'Catalan' },
  { code: 'ch', name: 'Chamorro' },
  { code: 'ce', name: 'Chechen' },
  { code: 'zh', name: 'Chinese' },
  { code: 'hr', name: 'Croatian' },
  { code: 'cs', name: 'Czech' },
  { code: 'da', name: 'Danish' },
  { code: 'dv', name: 'Divehi, Dhivehi, Maldivian' },
  { code: 'nl', name: 'Dutch' },
  { code: 'en', name: 'English' },
  { code: 'eo', name: 'Esperanto' },
  { code: 'et', name: 'Estonian' },
  { code: 'fi', name: 'Finnish' },
  { code: 'fr', name: 'French' },
  { code: 'gl', name: 'Galician' },
  { code: 'gd', name: 'Gaelic (Scottish)' },
  { code: 'gv', name: 'Gaelic (Manx)' },
  { code: 'ka', name: 'Georgian' },
  { code: 'de', name: 'German' },
  { code: 'el', name: 'Greek' },
  { code: 'kl', name: 'Greenlandic' },
  { code: 'he', name: 'Hebrew' },
  { code: 'hi', name: 'Hindi' },
  { code: 'hu', name: 'Hungarian' },
  { code: 'is', name: 'Icelandic' },
  { code: 'id, in', name: 'Indonesian' },
  { code: 'ga', name: 'Irish' },
  { code: 'it', name: 'Italian' },
  { code: 'ja', name: 'Japanese' },
  { code: 'kn', name: 'Kannada' },
  { code: 'ks', name: 'Kashmiri' },
  { code: 'kk', name: 'Kazakh' },
  { code: 'kg', name: 'Kongo' },
  { code: 'ko', name: 'Korean' },
  { code: 'ku', name: 'Kurdish' },
  { code: 'la', name: 'Latin' },
  { code: 'lv', name: 'Latvian (Lettish)' },
  { code: 'lt', name: 'Lithuanian' },
  { code: 'lg', name: 'Luganda, Ganda' },
  { code: 'lb', name: 'Luxembourgish' },
  { code: 'mk', name: 'Macedonian' },
  { code: 'mg', name: 'Malagasy' },
  { code: 'ms', name: 'Malay' },
  { code: 'ml', name: 'Malayalam' },
  { code: 'mt', name: 'Maltese' },
  { code: 'mh', name: 'Marshallese' },
  { code: 'mo', name: 'Moldavian' },
  { code: 'mn', name: 'Mongolian' },
  { code: 'ne', name: 'Nepali' },
  { code: 'no', name: 'Norwegian' },
  { code: 'fa', name: 'Persian (Farsi)' },
  { code: 'pl', name: 'Polish' },
  { code: 'pt', name: 'Portuguese' },
  { code: 'pa', name: 'Punjabi (Eastern)' },
  { code: 'ro', name: 'Romanian' },
  { code: 'ru', name: 'Russian' },
  { code: 'sr', name: 'Serbian' },
  { code: 'sk', name: 'Slovak' },
  { code: 'sl', name: 'Slovenian' },
  { code: 'so', name: 'Somali' },
  { code: 'es', name: 'Spanish' },
  { code: 'su', name: 'Sundanese' },
  { code: 'sw', name: 'Swahili (Kiswahili)' },
  { code: 'ss', name: 'Swati' },
  { code: 'sv', name: 'Swedish' },
  { code: 'tl', name: 'Tagalog' },
  { code: 'ty', name: 'Tahitian' },
  { code: 'tg', name: 'Tajik' },
  { code: 'ta', name: 'Tamil' },
  { code: 'tt', name: 'Tatar' },
  { code: 'te', name: 'Telugu' },
  { code: 'th', name: 'Thai' },
  { code: 'bo', name: 'Tibetan' },
  { code: 'ti', name: 'Tigrinya' },
  { code: 'to', name: 'Tonga' },
  { code: 'ts', name: 'Tsonga' },
  { code: 'tr', name: 'Turkish' },
  { code: 'tk', name: 'Turkmen' },
  { code: 'ug', name: 'Uyghur' },
  { code: 'uk', name: 'Ukrainian' },
  { code: 'uz', name: 'Uzbek' },
  { code: 'vi', name: 'Vietnamese' },
  { code: 'cy', name: 'Welsh' },
]

export const DrivingLicenses = {
  A: {
    value: "A",
    label: "A",
  },
  A1: {
    value: "A1",
    label: "A1",
  },
  B: {
    value: "B",
    label: "B",
  },
  BE: {
    value: "BE",
    label: "BE",
  },
  C1: {
    value: "C1",
    label: "C1",
  },
  C1E: {
    value: "C1E",
    label: "C1E",
  },
  C: {
    value: "C",
    label: "C",
  },
  CE: {
    value: "CE",
    label: "CE",
  },
  D1: {
    value: "D1",
    label: "D1",
  },
  D1E: {
    value: "D1E",
    label: "D1E",
  },
  D: {
    value: "D",
    label: "D",
  },
  DE: {
    value: "DE",
    label: "DE",
  },
  ...EnumFunctions,
}

export const INDUSTRIES = {
  AGRICULTURE: {
    value: "AGRICULTURE",
    label: "Agriculture",
  },
  CONSTRUCTION: {
    value: "CONSTRUCTION",
    label: "Construction",
  },
  CUSTOMER_SERVICE: {
    value: "CUSTOMER_SERVICE",
    label: "Customer Service",
  },
  EDUCATION: {
    value: "EDUCATION",
    label: "Education",
  },
  ENGINEERING: {
    value: "ENGINEERING",
    label: "Engineering",
  },
  FINANCE: {
    value: "FINANCE",
    label: "Finance",
  },
  GASTRONOMY: {
    value: "GASTRONOMY",
    label: "Gastronomy",
  },
  GOVERNMENT_PUBLIC_ADMINISTRATION: {
    value: "GOVERNMENT_PUBLIC_ADMINISTRATION",
    label: "Government/Public Administration",
  },
  HEALTH_FITNESS: {
    value: "HEALTH_FITNESS",
    label: "Health/Fitness",
  },
  HEALTHCARE: {
    value: "HEALTHCARE",
    label: "Healthcare",
  },
  HOSPITALITY: {
    value: "HOSPITALITY",
    label: "Hospitality",
  },
  HUMAN_RESOURCES: {
    value: "HUMAN_RESOURCES",
    label: "Human Resources",
  },
  INFORMATION_TECHNOLOGY: {
    value: "INFORMATION_TECHNOLOGY",
    label: "Information Technology (IT)",
  },
  LAW_ENFORCEMENT: {
    value: "LAW_ENFORCEMENT",
    label: "Law Enforcement",
  },
  LEGAL: {
    value: "LEGAL",
    label: "Legal",
  },
  MANUFACTURING: {
    value: "MANUFACTURING",
    label: "Manufacturing",
  },
  MARKETING_ADVERTISING: {
    value: "MARKETING_ADVERTISING",
    label: "Marketing/Advertising",
  },
  MEDIA_ENTERTAINMENT: {
    value: "MEDIA_ENTERTAINMENT",
    label: "Media/Entertainment",
  },
  NON_PROFIT_NGO: {
    value: "NON_PROFIT_NGO",
    label: "Non-profit/NGO",
  },
  OUTSOURCING: {
    value: "OUTSOURCING",
    label: "Outsourcing/Offshoring",
  },
  POLITICAL_ORGANIZATION: {
    value: "POLITICAL_ORGANIZATION",
    label: "Political Organization",
  },
  REAL_ESTATE: {
    value: "REAL_ESTATE",
    label: "Real Estate",
  },
  RELIGIOUS_INSTITUTIONS: {
    value: "RELIGIOUS_INSTITUTIONS",
    label: "Religious Institutions",
  },
  RETAIL: {
    value: "RETAIL",
    label: "Retail",
  },
  SALES: {
    value: "SALES",
    label: "Sales",
  },
  TRANSPORTATION_LOGISTICS: {
    value: "TRANSPORTATION_LOGISTICS",
    label: "Transportation/Logistics",
  },
  OTHER_SERVICES: {
    value: "OTHER_SERVICES",
    label: "Other Services",
  },
  OTHER_PRODUCTION: {
    value: "OTHER_PRODUCTION",
    label: "Other Production",
  },
  OTHER_TECHNOLOGY: {
    value: "OTHER_TECHNOLOGY",
    label: "Other Technology",
  },
  OTHER_INDUSTRY: {
    value: "OTHER_INDUSTRY",
    label: "Other Industry",
  },
  ...EnumFunctions,
}