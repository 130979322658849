import { createAsyncThunk } from "@reduxjs/toolkit";
import { makeRequest } from "../../utilities/api";

export const getUsers = createAsyncThunk("users/getUsers", async (values, { rejectWithValue }) => makeRequest("/users", "GET", values, rejectWithValue));

export const getCurrentUser = createAsyncThunk("user/getCurrentUser", async (values, { rejectWithValue }) => makeRequest("/users/me", "GET", values, rejectWithValue));

export const getUserById = createAsyncThunk("users/getUserById", async (values, { rejectWithValue }) => makeRequest(`/users/${values.id}`, "GET", values, rejectWithValue));

export const patchUser = createAsyncThunk("users/patch", async (values, { rejectWithValue }) => makeRequest(`/users/${values.id}`, "PATCH", values, rejectWithValue));

export const deleteUser = createAsyncThunk("users/patch", async (values, { rejectWithValue }) => makeRequest(`/users/${values}`, "DELETE", values, rejectWithValue));

export const addReferralToUser = createAsyncThunk("users/addReferralToUser", async (values, { rejectWithValue }) => makeRequest(`/users/referral`, "POST", values, rejectWithValue));
