import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { PDFDownloadLink } from "@react-pdf/renderer";

import SecondaryButton from "../SecondaryButton";
import PdfProfile from "./PdfProfile";

const PdfProfileViewButton = ({ selectedUser, privacy }) => {
  const [userData, setUserData] = useState(null);

  const getBase64FromUrl = async (url) => {
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Cache-Control': 'no-cache'
        }
      });
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    const prepareData = async () => {
      let base64Image = null;
      if (selectedUser?.profile?.profilePhoto?.fileUrl) {
        base64Image = await getBase64FromUrl(selectedUser.profile.profilePhoto.fileUrl);
      }
      setUserData({
        ...selectedUser,
        ...selectedUser.profile,
        profilePhoto: {
          fileUrl: base64Image
        }
      })
    };

    prepareData();
  }, [selectedUser]);

  if (!userData) {
    return <SecondaryButton disabled>Loading...</SecondaryButton>;
  }

  return userData && (
    <PDFDownloadLink
      document={<PdfProfile privacy={privacy} data={userData} />}
      fileName={`${selectedUser?.firstName}-${selectedUser?.lastName}-Resume.pdf`}
    >
      {({ loading }) => (
        <SecondaryButton
          disabled={loading}
          sx={{
            color: '#C75C57',
            "& :hover": { color: 'white' },
            textDecoration: 'none !important'
          }}
        >
          <span style={{ textDecoration: 'none !important' }}>
            {loading ? 'Preparing PDF...' : 'Download PDF'}
          </span>
        </SecondaryButton>
      )}
    </PDFDownloadLink>
  );
};

PdfProfileViewButton.propTypes = {
  selectedUser: PropTypes.objectOf({}).isRequired,
  privacy: PropTypes.string.isRequired,
}

export default PdfProfileViewButton;
