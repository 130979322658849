/* eslint-disable react/no-array-index-key */
import { Box, Card, CardActions, CardContent, Grid, Typography } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import _ from "lodash";
import PropTypes from "prop-types";

import { Roles, SalaryType } from "../../utilities/constants";
import { getCurrentUserSelector } from "../../redux/users/selector";
import SecondaryButton from "./SecondaryButton";

const MinJobCard = ({ jobAd, vertical }) => {
  const navigate = useNavigate();
  const user = useSelector(getCurrentUserSelector);

  const content = [
    {
      icon: <CalendarMonthIcon sx={{ color: "#C02026" }} />,
      content: (
        <>
          <Typography variant="h6" sx={{ fontSize: "14px" }}>
            Salary:{" "}
          </Typography>
          <Typography fontWeight="bold">
            {parseFloat(jobAd?.salary)} €{jobAd.salaryType === SalaryType.HOURLY.value && "/hour"}
          </Typography>
        </>
      ),
      includeInVertical: true,
    },
    {
      icon: <CalendarMonthIcon sx={{ color: "#C02026" }} />,
      content: (
        <>
          <Typography variant="h6" sx={{ fontSize: "14px" }}>
            Start Date:
          </Typography>
          <Typography fontWeight="bold">{moment(jobAd.startDate).format("DD/MM/YYYY")}</Typography>
        </>
      ),
    },
    {
      icon: <LocationOnIcon sx={{ color: "#C02026" }} />,
      content: (
        <>
          <Typography variant="h6" sx={{ fontSize: "14px" }}>
            Location:
          </Typography>
          <Typography fontWeight="bold">{jobAd?.address?.city}</Typography>
        </>
      ),
      includeInVertical: true,
    },
  ];

  return (
    <Card sx={{ width: "100%", padding: "8px", marginBottom: "15px", border: '2px solid black', borderRadius: '8px' }}>
      <CardContent sx={{ wordWrap: "break-word", paddingRight: "35px", textAlign: "left" }}>
        <Typography gutterBottom variant="h5" fontWeight='bold' component="div">
          {_.truncate(jobAd.title, { length: 45, separator: "..." })}
        </Typography>
      </CardContent>
      {vertical ? (
        <Box>
          <Grid container>
            {content
              .filter((i) => i?.includeInVertical)
              .map((i) => (
                <Grid item md={6} lg={6} key={i.content}>
                  <Box margin="auto">
                    {i?.icon}
                    {i.content}
                  </Box>
                </Grid>
              ))}
          </Grid>
          <Box margin="20px auto 10px">
            <SecondaryButton onClick={() => navigate(`/jobs/${jobAd.id}`)} sx={{ fontSize: "15px !important" }}>
              <span>
                {user?.role === Roles.ADMIN.value || (user?.role === Roles.COMPANY.value && jobAd?.companyId === user.companyId) ? "Edit" : "View"}
              </span>
            </SecondaryButton>
          </Box>
        </Box>
      ) : (
        <CardActions>
          {content.map((i, index) => (
            <Box margin="auto" key={`${i.content}-${index}`}>
              {i?.icon}
              {i.content}
            </Box>
          ))}
          <Box margin="auto">
            <SecondaryButton size="small" onClick={() => navigate(`/jobs/${jobAd.id}`)}>
              <span>
                {user?.role === Roles.ADMIN.value || (user?.role === Roles.COMPANY.value && jobAd?.companyId === user.companyId) ? "Edit" : "View"}
              </span>
            </SecondaryButton>
          </Box>
        </CardActions>
      )}
    </Card>
  );
};

MinJobCard.propTypes = {
  jobAd: PropTypes.shape({
    id: PropTypes.number.isRequired,
    salary: PropTypes.string.isRequired,
    salaryType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    address: PropTypes.shape({
      city: PropTypes.string.isRequired,
    }),
    companyId: PropTypes.number.isRequired,
  }).isRequired,
  vertical: PropTypes.bool,
};

MinJobCard.defaultProps = {
  vertical: false,
};

export default MinJobCard;
