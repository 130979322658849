import {Typography, Paper, Box, Grid, FormLabel} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import {Controller} from "react-hook-form";
import _ from "lodash";
import PropTypes from "prop-types";

import ErrorBox from "../shared/ErrorBox";
import SecondaryButton from "../shared/SecondaryButton";

import { PHOTO_INFO_DOC_URL } from '../../utilities/constants';

const FileUpload = ({field, register, control, errors, values}) => {
    const [files, setFiles] = useState([]);
    const hiddenFileInput = useRef(null);

    useEffect(() => {
        if (values !== undefined) {
            setFiles(values);
        }
    }, [values]);

    const getFormLabel = () => (
        <FormLabel component="legend" sx={{mb: 2, textAlign: "left", ...(field.label === "" && {minHeight: "20px"})}}>
            <Typography variant="h6">
                {field?.label}
                &nbsp;
                {field?.isRequired && <span>*</span>}
            </Typography>
            <Typography variant="body1" component="span">
                {field?.helpText}
            </Typography>
        </FormLabel>
    )

    const getFormField = () => (
        <Box>
            <Box>
                <Controller
                    control={control}
                    id={field.fieldId}
                    defaultValue={[]}
                    className={field.fieldClass}
                    name={field.name}
                    inputRef={register(field.name)}
                    render={({field: {onChange, value, ...rest}}) => (
                        <>
                            <SecondaryButton onClick={() => hiddenFileInput.current.click()}
                                             sx={{borderRadius: 0, width: '100%', padding: '7px 10px'}}>
                                <span>Upload Photo</span>
                            </SecondaryButton>
                            <Box>
                                {files && <Typography variant="p">
                                    {files.name}
                                </Typography>}
                            </Box>
                            <input
                                type="file"
                                hidden
                                disabled={field.disabled}
                                multiple={field?.multiple}
                                name={field.name}
                                {...rest}
                                value={value?.filename}
                                ref={hiddenFileInput}
                                onChange={(e) => {
                                    let newFiles;
                                    if (field?.multiple) {
                                        newFiles = [...files, ...Object.values(e.target.files).map((val) => val)];
                                    } else {
                                        [newFiles] = e.target.files;
                                    }
                                    setFiles(newFiles);
                                    return onChange(newFiles);
                                }}
                            />
                        </>
                    )}
                />
            </Box>
            <Box>
                <Typography
                    sx={{
                        color: "#526280",
                        cursor: 'pointer',
                        textTransform: 'uppercase',
                        marginTop: '10px',
                        '&:hover': {
                            color: '#C02026',
                        },
                    }}
                    onClick={() => {
                        // const docUrl = 'https://posrednik24-public.s3.eu-central-1.amazonaws.com/Photo+Instructions.pdf';
                        window.open(PHOTO_INFO_DOC_URL, '_blank');
                    }}
                >
                    Photo info
                </Typography>
                {field.additionalInformation && (
                    <Typography variant="p" sx={{color: "#526280"}}>
                        {field.additionalInformation}
                    </Typography>
                )}
                {field.allowedFormats && (
                    <Typography variant="h6" sx={{color: "#787878", fontSize: "13px"}}>
                        FILETYPES ACCEPTED: {field.allowedFormats.map((item) => item.toUpperCase()).join(", ")}
                    </Typography>
                )}
                MAX PHOTO SIZE: 10 MB
            </Box>
            <ErrorBox message={_.get(errors, field.name)?.message || ""}/>
        </Box>
    )

    return (
        <Paper align="center" elevation={0} sx={{marginBottom: "25px"}}>
            {field?.options?.sameRow ? (
                <Grid container>
                    <Grid item xs={4} sm={3} md={4} lg={4}>
                        {getFormLabel()}
                    </Grid>
                    <Grid item xs={8} sm={9} md={8} lg={8}>
                        {getFormField()}
                    </Grid>
                </Grid>
            ) : (
                <Box>
                    {getFormLabel()}
                    {getFormField()}
                </Box>
            )}
        </Paper>
    );
};

FileUpload.propTypes = {
    field: PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        isRequired: PropTypes.bool,
        multiple: PropTypes.bool,
        helpText: PropTypes.string,
        isImages: PropTypes.bool,
        disabled: PropTypes.bool,
        fieldId: PropTypes.string,
        fieldClass: PropTypes.string,
        placeholder: PropTypes.string,
        options: PropTypes.shape({
            sameRow: PropTypes.bool,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]),
            label: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]),
        }).isRequired,
        style: PropTypes.shape({
            size: PropTypes.string.isRequired,
        }),
        // eslint-disable-next-line react/forbid-prop-types
        additionalInformation: PropTypes.object,
        allowedFormats: PropTypes.arrayOf(PropTypes.string),
        photoInfoDoc: PropTypes.string.isRequired,
    }),
    register: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    control: PropTypes.object.isRequired,
    errors: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    values: PropTypes.arrayOf(PropTypes.any),
    setValue: PropTypes.func.isRequired,
};

FileUpload.defaultProps = {
    field: {
        isRequired: false,
        options: {
            sameRow: false,
        },
    },
    values: [],
};

export default FileUpload;
