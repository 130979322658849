import * as yup from "yup";
import React from 'react';

export const registerSchema = yup
    .object()
    .shape({
        email: yup.string("The email is not valid.").email("Please enter a valid email.").required("Please enter your email."),
        tos: yup
            .bool("The TOS is not valid.")
            .required("Please accept our Terms of Service.")
            .test("tos-accept", "You must accept our Terms of Service to continue.", (val) => val === true),
    })
    .required();

export const formSteps = [
    {
        stepNo: 1,
        fullWidth: true,
        fields: [
            {
                name: "email",
                fieldId: "email",
                label: "Email address",
                type: "input-text",
                isRequired: true,
                size: 12,
            },
            {
                name: "tos",
                fieldId: "tos",
                label: React.createElement(
                    "span",
                    null,
                    "I have read and understood the privacy policy and agree to the ",
                    React.createElement(
                        "a",
                        {
                            href: "https://posrednik24.com/terms-and-conditions/",
                            target: "_blank",
                            style: {
                                color: "rgb(83, 114, 166)",
                                textDecoration: "underline",
                                fontFamily: "Poppins, sans-serif",
                                fontSize: "16px",
                                lineHeight: "1.6",
                                letterSpacing: "0.0075em",
                            },
                        },
                        "Terms & Conditions"
                    ),
                    "."
                ),
                isRequired: true,
                type: "checkbox",
                size: 12,
            },
        ],
    },
];
