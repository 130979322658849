import * as yup from "yup";
import EuroIcon from "@mui/icons-material/Euro";
import dayjs from "dayjs";

import { JobCategories, INDUSTRIES, JobTimeTypes, RequiredDocuments, Roles, SalaryType } from "../../../utilities/constants";

export const jobFormSchema = (user, documents) =>
  yup.object().shape({
    title: yup.string("The title field is not valid.").required("The title field is required."),
    startDate: yup
      .string()
      .typeError("The date is not valid.")
      .required("The start date is required.")
      .test("start-date-validation", "The start date must be in the future.", (val) => !val || (dayjs.isDayjs(val) ? val : dayjs(val)).isAfter(dayjs()))
      .transform((value) => (Number.isNaN(value) || value === undefined ? null : value))
      .nullable(),
    endDate: yup
      .string()
      .required("The end date is required.")
      .test("start-date-validation", "The end date must be in the future.", (val) => !val || (dayjs.isDayjs(val) ? val : dayjs(val)).isAfter(dayjs()))
      .transform((value) => (Number.isNaN(value) || value === undefined ? null : value))
      .when(["startDate"], (startDate) => {
        if (startDate) {
          return yup.string().typeError("The end date is required.")
            .test("end-date-validation", "The end date must be after the start date.", (val) => !val || (dayjs.isDayjs(val) ? val : dayjs(val)).isAfter(dayjs(startDate)));
        }
        return yup.string().required('The end date is required.');
      })
      .nullable(),
    salaryType: yup
      .string("The salary type is not valid.")
      .required("The salary type is required.")
      .test("salary-type-validation", `The salary type must be one of ${SalaryType.getLabels().join(", ")}.`, (val) => SalaryType.getValues().includes(val)),
    salary: yup
      .string("The salary field is not valid.")
      .required("The salary field is required.")
      .transform((value) => value && parseFloat(value).toFixed(2)),
    availablePositions: yup
      .number("The available positions field is not valid.")
      .required("The available positions field is required.")
      .transform((value) => (Number.isNaN(value) || !value || false ? 0 : value))
      .min(1, "The available positions must be greater than 1."),
    industry: yup
      .string("The industry field is not valid.")
      .required("The industry field is required.")
      .test("industry-validation", `The industry must be one of ${INDUSTRIES.getLabels().join(", ")}.`, (val) => INDUSTRIES.getValues().includes(val)),
    jobCategories: yup
      .array()
      .required("The category field is required."),
    jobTimeType: yup
      .string("The job type field is not valid.")
      .required("The job type field is required.")
      .test("jobType-validation", `The job type must be one of ${JobTimeTypes.getLabels().join(", ")}.`, (val) => JobTimeTypes.getValues().includes(val)),
    documents: yup
      .object()
      .shape({
        requiredDocuments: yup
          .array()
          .min(1, 'At least one document must be required.')
          .test(
            "requiredDocuments-validation",
            `The required documents must be one of ${[...RequiredDocuments.getLabels(), ...(documents || []).map((i) => i.name).join(", ")]}.`,
            (vals) => !vals || vals.map((i) => [...RequiredDocuments.getValues(), ...(documents || []).map((doc) => doc.name)].includes(i)).every((i) => i)
          )
          .nullable(),
      }),
    address: yup.object().shape({
      country: yup.string("The country is not valid.").trim().required("Please enter the country."),
      city: yup.string("The city is not valid.").trim().required("Please enter the city."),
      address: yup.string("The address is not valid.").trim().required("Please enter the address."),
      zipCode: yup
        .string("The zip code is not valid.")
        .trim()
        .min(4, "The ZIP code must be at least 4 characters long.")
        .required("Please enter the zip code.")
        .matches(/^[0-9]+$/, "The zip code must only contain numbers."),
    }),
    description: yup.string("The description field is not valid.").required("The description field is required."),
    qualifications: yup.array().min(1, "The qualifications field is required.").required("The qualifications field is required."),
    requirements: yup.array().min(1, "The requirements field is required.").required("The requirements field is required."),
    companyId: yup.number("The company ID is not valid.").test("admin-company-id", "The company field is required.", (val) => user.role !== Roles.ADMIN.value || val !== undefined),
  });

export const jobFormSteps = (user, companies = [], fileTypes = []) => [
  {
    stepNo: 1,
    fields: [
      {
        name: "title",
        fieldId: "title",
        label: "Title",
        type: "input-text",
        isRequired: true,
        size: 12,
      },
      {
        name: "startDate",
        fieldId: "startDate",
        label: "Start Date",
        type: "input-date",
        isRequired: true,
        size: 5,
      },
      {
        name: "endDate",
        fieldId: "endDate",
        label: "End Date",
        type: "input-date",
        floatRight: true,
        isRequired: true,
        size: 5,
      },
      {
        name: "salaryType",
        fieldId: "salaryType",
        label: "Salary Type",
        type: "select",
        values: [{ value: "", label: "Please select a salary type..." }, ...SalaryType.asList()],
        isRequired: true,
        size: 5,
      },
      {
        name: "salary",
        fieldId: "salary",
        label: "Salary",
        type: "input-number",
        icon: <EuroIcon />,
        isRequired: true,
        floatRight: true,
        size: 5,
      },
      {
        name: "availablePositions",
        fieldId: "availablePositions",
        label: "Available Positions",
        type: "input-number",
        isRequired: true,
        size: 5,
      },
      {
        name: "companyId",
        fieldId: "companyId",
        label: "Company",
        type: "select",
        values: [{ label: "Please select a company", value: "" }, ...companies.map((item) => ({ label: item.name, value: item.id }))],
        condition: user.role === Roles.ADMIN.value,
        isRequired: true,
        floatRight: true,
        size: 5,
      },
    ],
  },
  {
    stepNo: 2,
    fields: [
      {
        name: "industry",
        fieldId: "industry",
        label: "Industry",
        type: "select",
        values: [{ label: "Select job industry...", value: "" }, ...INDUSTRIES.asList()],
        isRequired: true,
        size: 5,
      },
      {
        name: "jobCategories",
        fieldId: "category",
        label: "Categories",
        type: "select",
        values: [{ label: "Select job category...", value: "" }, ...JobCategories.asList()],
        isRequired: true,
        multiple: true,
        floatRight: true,
        size: 5,
      },
      {
        name: "jobTimeType",
        fieldId: "jobTimeType",
        label: "Job Type",
        type: "select",
        values: [{ label: "Select job type...", value: "" }, ...JobTimeTypes.asList()],
        isRequired: true,
        size: 5,
      },
      {
        name: "documents.requiredDocuments",
        fieldId: "requiredDocuments",
        label: "Required Documents",
        type: "select",
        multiple: true,
        values: [{ label: "Select required documents...", value: "" }, ...fileTypes],
        floatRight: true,
        isRequired: true,
        size: 5,
      },
      {
        name: "isFoodIncluded",
        fieldId: "isFoodIncluded",
        label: "Food Included",
        type: "checkbox",
        isRequired: true,
        floatRight: true,
        size: 3,
      },
      {
        name: "isAccommodationIncluded",
        fieldId: "isAccommodationIncluded",
        label: "Accommodation Included",
        type: "checkbox",
        isRequired: true,
        floatRight: true,
        size: 3,
      },
      {
        name: "isTransportIncluded",
        fieldId: "isTransportIncluded",
        label: "Transport Included",
        type: "checkbox",
        isRequired: true,
        floatRight: true,
        size: 3,
      },
      {
        name: "address.country",
        fieldId: "address.country",
        label: "Country",
        type: "input-text",
        isRequired: true,
        size: 5,
      },
      {
        name: "address.city",
        fieldId: "address.city",
        label: "City",
        type: "input-text",
        floatRight: true,
        isRequired: true,
        size: 5,
      },
      {
        name: "address.zipCode",
        fieldId: "address.zipCode",
        label: "Zip Code",
        type: "input-text",
        isRequired: true,
        size: 4,
      },
      {
        name: "address.address",
        fieldId: "address.address",
        label: "Address",
        type: "input-text",
        isRequired: true,
        floatRight: true,
        size: 7,
      },
    ],
  },
  {
    stepNo: 3,
    fields: [
      {
        name: "description",
        fieldId: "description",
        label: "Description",
        type: "input-text-area",
        isRequired: true,
        size: 12,
      },

      {
        name: "qualifications",
        fieldId: "qualifications",
        label: "Qualifications",
        type: "input-tags",
        list: true,
        isRequired: true,
        size: 5,
      },
      {
        name: "requirements",
        fieldId: "requirements",
        label: "Requirements",
        type: "input-tags",
        isRequired: true,
        list: true,
        floatRight: true,
        size: 5,
      },
    ],
  },
];
