import { useEffect, useState } from "react";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Box, Checkbox, ListItemText, MenuItem, Select } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { deleteUser, getUsers, patchUser } from "../../../redux/users/actions";
import { capitalizeFirstLetter } from "../../../utilities";
import Table from "../../../components/shared/Table";
import PrimaryButton from "../../../components/shared/PrimaryButton";
import { getCurrentUserSelector } from "../../../redux/users/selector";
import { Roles } from "../../../utilities/constants";
import Filter from "../../../components/shared/Filter";
import { filterFormFields, filterFormSchema } from "./components/filterDetails";
import { setPreviousPath } from "../../../redux/utilities/actions";
import SecondaryButton from "../../../components/shared/SecondaryButton";

export const ViewButton = ({ id, onClick }) => (
  // eslint-disable-next-line react/destructuring-assignment
  <Link to={`/users/${id}`}>
    <PrimaryButton onClick={onClick}>View</PrimaryButton>
  </Link>
);

ViewButton.propTypes = {
  id: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

const CustomCheckbox = ({ checked, onClick, disabled }) => <Checkbox disabled={disabled} checked={checked} onClick={onClick} />;

CustomCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const columns = (dispatch, promoters, handleDeleteUser) => [
  {
    id: "id",
    label: "ID",
    props: {
      align: "center",
    },
  },
  {
    id: "email",
    label: "E-mail",
    value: (row) => row?.email,
    props: {
      align: "center",
    },
  },
  {
    id: "isEmailVerified",
    label: "Email Verified",
    type: "button",
    value: (row) => <CustomCheckbox checked={row.isEmailVerified} onClick={(val) => dispatch(patchUser({ id: row.id, isEmailVerified: val.target.checked }))} />,
    props: {
      align: "center",
    },
  },
  {
    id: "isAccountVerified",
    label: "Account Verified",
    type: "button",
    value: (row) => <CustomCheckbox disabled={row.isAccountVerified} checked={row.isAccountVerified} onClick={(val) => dispatch(patchUser({ id: row.id, isAccountVerified: val.target.checked }))} />,
    props: {
      align: "center",
    },
  },
  {
    id: "role",
    label: "Role",
    value: (row) => (
      <Select sx={{ height: "45px" }} value={row.role} onChange={(e) => dispatch(patchUser({ id: row.id, role: e.target.value }))}>
        {Roles.asList().map((val) => (
          <MenuItem key={val.value} value={val.value} selected={val.value === row.role}>
            <ListItemText primary={val?.label} />
          </MenuItem>
        ))}
      </Select>
    ),
    props: {
      align: "center",
    },
  },
  {
    id: "promoter",
    label: "Promoter",
    value: (row) => (
      <Select onChange={(val) => dispatch(patchUser({ id: row.id, referralEmail: val.target.value }))} value={row.referral}>
        <MenuItem key='default-val' value={null} selected={row.referral === undefined} disabled>
          <ListItemText primary='Select a promoter' />
        </MenuItem>
        {promoters.map((val) => (
          <MenuItem key={val.email} value={val.email} selected={val.email === row.referral}>
            <ListItemText primary={val.email} />
          </MenuItem>
        ))}
      </Select>
    ),
    props: {
      align: "center",
    },
  },
  {
    id: "createdAt",
    label: "Registration Date",
    value: (row) => capitalizeFirstLetter(moment(row.createdAt).format("DD/MM/YYYY hh:mm")),
    props: {
      align: "center",
    },
  },
  {
    id: "blocked",
    label: "Blocked",
    type: "button",
    value: (row) => <CustomCheckbox checked={!row.isEnabled} onClick={(val) => dispatch(patchUser({ id: row.id, isEnabled: !val.target.checked }))} />,
    props: {
      align: "center",
    },
  },
  {
    id: "view-button",
    value: (row) => <ViewButton id={row.id} onClick={() => dispatch(setPreviousPath("/users"))} />,
    type: "button",
    props: {
      size: "small",
      align: "right",
      sx: {
        width: "10%",
      },
    },
  },
  {
    id: "view-button",
    value: (row) => (
      <SecondaryButton onClick={() => handleDeleteUser(row.id)}>Delete</SecondaryButton>
    ),
    type: "button",
    props: {
      size: "small",
      align: "right",
      sx: {
        width: "10%",
      },
    },
  },
];

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector(getCurrentUserSelector);
  const users = useSelector((state) => state.users.data || []);
  const promoters = useSelector((state) => state.users.data.filter((user) => user.role === Roles.PROMOTER.value));
  const [filteredUsers, setFilteredUsers] = useState([]);

  useEffect(() => {
    if (currentUser.role !== Roles.ADMIN.value) {
      navigate("/jobs");
    }
  }, [currentUser]);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const handleDeleteUser = (rowId) => {
    dispatch(deleteUser(rowId)).then(() => {
      setFilteredUsers((oldValue) => oldValue.filter((i) => i.id !== rowId));
    })
  }

  return (
    <Box>
      {users && <Filter items={users} setFilteredItems={setFilteredUsers} filterSteps={filterFormFields(promoters)} filterSchema={filterFormSchema} />}
      <Table columns={columns(dispatch, promoters, handleDeleteUser)} data={filteredUsers || []} noResultsContent="There are no users." />
    </Box>
  );
};

export default Users;
