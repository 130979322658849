import { useState } from "react";

import { Box, Grid } from "@mui/material";
import PropTypes from "prop-types";

import CheckBox from "./CheckBox";
import DateField from "./DateField";
import FileUpload from "./FileUpload";
import Radio from "./Radio";
import RadioGroup from "./RadioGroup";
import SelectField from "./SelectField";
import TextField from "./TextField";
import Banner from "./Banner";
import TagsTextField from "./TagsTextField";
import ShowableFields from "./ShowableFields";
import PhoneField from "./PhoneField";
// eslint-disable-next-line import/no-cycle
import FormFieldsArray from "./FormFieldsArray";

const FormFields = ({ page, isLoading, form }) => {
  const [depends, setDepends] = useState(true);
  const { fields } = page;
  const { register, control, watch, formState: { errors }, getValues, setValue } = form;

  const constructField = (field) => {
    switch (field.type) {
      case "checkbox":
        return <CheckBox field={{ ...field, disabled: field.disabled }} register={register} control={control} errors={errors} isLoading={isLoading} />;
      case "input-radio":
        return <Radio controlDepends={setDepends} field={{ ...field, disabled: field.disabled }} register={register} control={control} errors={errors} isLoading={isLoading} />;
      case "input-password":
      case "input-email":
      case "input-number":
      case "input-url":
      case "input-text":
      case "input-text-area":
        return <TextField field={{ ...field, disabled: field.disabled }} register={register} errors={errors} isLoading={isLoading} />;
      case "input-tags":
        return <TagsTextField field={{ ...field, disabled: field.disabled }} register={register} control={control} errors={errors} isLoading={isLoading} setValue={setValue} />;
      case "input-date":
        return <DateField fieldDetails={{ ...field, disabled: typeof field.disabled === 'function' ? field.disabled(watch(field?.disabledDepends), setValue) : field.disabled }} register={register} control={control} errors={errors} setValue={setValue} isLoading={isLoading} getValues={getValues} />;
      case "select":
        return <SelectField field={{ ...field, disabled: field.disabled }} register={register} control={control} errors={errors} isLoading={isLoading} />;
      case "radio-group":
        return <RadioGroup field={{ ...field, disabled: field.disabled }} register={register} control={control} errors={errors} isLoading={isLoading} />;
      case "file":
        return <FileUpload register={register} control={control} errors={errors} field={{ ...field, disabled: field.disabled }} values={getValues(field.name)} setValue={setValue} />;
      case "banner":
        return <Banner field={field} />;
      case "input-tel":
        return <PhoneField
          errors={errors}
          value={getValues(field.name)}
          field={field}
        />
      case "empty":
        return <Box style={{ paddingBottom: '30px' }}/>
      default:
        throw new Error(`${field.type} is not a valid field type.`);
    }
  };

  const renderFields = () => {
    let index = null;
    return fields?.map((field) => {
      if (Array.isArray(field)) {
        if (index !== null) {
          index += 1;
        } else {
          index = 0;
        }
      }
      return Array.isArray(field) ? (
        <FormFieldsArray watch={watch} errors={errors} setValue={setValue} field={field} fieldInfo={page.multiple[index]} />
      ) : (typeof(field) === 'object' && field.showable ? <ShowableFields constructField={constructField} showableFields={field} /> : (!(field.hidden && depends) && (
          <Grid
            item
            xs={12}
            sm={12}
            md={field.size}
            lg={field.size}
            xl={field.size}
            key={field.name}
            sx={{
              padding: field?.style?.padding,
              margin: field.type === "file" && field?.options && field.options.size === "small" ? "auto 0 0 auto" : `10px ${field?.floatRight ? "0 0 auto" : "0"}`,
            }}
          >
            {constructField(field)}
          </Grid>
        ))
      );
    });
  };

  return renderFields();
};

FormFields.propTypes = {
  page: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  isLoading: PropTypes.bool.isRequired,
  renderInputFields: PropTypes.bool,
  form: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
};

FormFields.defaultValues = {
  renderInputFields: true,
};

export default FormFields;
